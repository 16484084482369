import { useStaticQuery, graphql } from "gatsby";

export const useFluggebieteQuery = (locale) => {
  const fluggebieteData = useStaticQuery(graphql`
    query FluggebieteQuery {
      allGraphCmsFluggebiet {
        edges {
          node {
            id
            locale
            fluggebietTopline
            fluggebietHeadline
            fluggebietBeschreibung
            fluggebietImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageAltText
            buttonPreis
            buttonDetails
          }
        }
      }
    }
  `);

  return fluggebieteData.allGraphCmsFluggebiet.edges.filter((edge) => {
    return edge.node.locale === locale;
  });
};
