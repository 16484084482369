import styled from "styled-components";

export const FormInputs = styled.div`
  color: #212121;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const FormLabel = styled.label`
  color: #fff;
  padding: 12px 12px 12px 0;
  display: inline-block;
`;
export const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0;
  }
`;
export const ContactFormWrapper = styled.div``;
export const SubmitButtonWrapper = styled.div``;
