import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
const ImageSlider = ({ images }) => {
  const settings = {
    autoPlay: true,
    infiniteLoop: true,
    showThumbs: false,
    showStatus: false,
  };

  return (
    <>
      <Carousel {...settings}>
        {images.map((element) => {
          var gatsbyImage = getImage(element.localFile.childrenImageSharp[0]);
          return (
            <div key={element.id}>
              <GatsbyImage image={gatsbyImage} alt={"Image"} />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default ImageSlider;
