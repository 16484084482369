import React, { useState, useEffect } from "react";
import useForm from "./hooks/useVoucherForm";
import { useVoucherReturnMessageQuery } from "./hooks/useVoucherReturnMessageQuery";
import {
  Column2,
  Img,
  ImgWrap,
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrapMobileOnly,
} from "../InfoSection/InfoElements";
import { Button } from "../ButtonElement";
import { useVoucherInfoQuery } from "../../hooks/useVoucherInfoQuery";
import { getImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import VoucherForm from "./VoucherForm";
import VoucherModal from "./VoucherModal";
import validate from "./functions/validateInfo";

import { RichText } from "@graphcms/rich-text-react-renderer";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";
  switch (lang) {
    case "de":
      return "de";
    default:
      return "en";
  }
};
const Voucher = () => {
  const intl = useIntl();
  const locale = intl.locale;

  var voucherData = useVoucherInfoQuery(locale)[0].node;
  const returnMessage =
    useVoucherReturnMessageQuery(locale)[0].node.voucherMessage;

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    submissionState,
    resetSubmission,
  } = useForm(validate);

  var data = {
    id: "voucher",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: voucherData.voucherTopline,
    headline: voucherData.voucherHeadline,
    description: voucherData.voucherText,
    buttonLabel: voucherData.voucherButtonLabel,
    imgStart: false,
    alt: voucherData.voucherHeadline,
    dark: true,
    primary: true,
    darkText: true,
    buttonLinkType: "reactRouter",
    buttonLinkTo: "/voucher",
  };
  var voucherImage = getImage(
    voucherData?.voucherImage?.localFile?.childImageSharp
  );

  const [showModel, setShowModel] = useState(false);
  const [view, setView] = useState("FORM");

  useEffect(() => {
    if (submissionState.submissionCompleted === true) {
      setView("SUCCESS");
    } else {
      setView("FORM");
    }
  }, [submissionState]);

  const handleVoucherFormClose = () => {
    setShowModel(false);
    resetSubmission();
  };

  return (
    <>
      <InfoContainer lightBg={data.lightBg} id={data.id}>
        <InfoWrapper>
          <InfoRow imgStart={data.imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{data.topLine}</TopLine>
                <Heading lightText={data.lightText}>{data.headline}</Heading>
                <ImgWrapMobileOnly>
                  <Img image={voucherImage} alt={data.alt} />
                </ImgWrapMobileOnly>
                <Subtitle darkText={data.darkText}>{data.description}</Subtitle>
                <BtnWrap>
                  <Button
                    onClick={() => setShowModel(true)}
                    primary={data.primary ? 1 : 0}
                    dark={data.dark ? 1 : 0}
                    dark2={data.dark2 ? 1 : 0}
                  >
                    {data.buttonLabel}
                  </Button>
                </BtnWrap>
                {showModel && (
                  <VoucherModal
                    title={intl.formatMessage({ id: "voucherTitle" })}
                    show={showModel}
                    handleClose={handleVoucherFormClose}
                    handleSubmit={handleSubmit}
                    view={view}
                  >
                    {!submissionState.submissionCompleted ? (
                      <VoucherForm
                        handleChange={handleChange}
                        values={values}
                        handleSubmit={handleSubmit}
                        errors={errors}
                      />
                    ) : (
                      <div style={{ margin: "1rem" }}>
                        <RichText content={returnMessage.raw} />
                      </div>
                    )}
                  </VoucherModal>
                )}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img image={voucherImage} alt={data.alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Voucher;
