import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  FlightProcessContainer,
  FlightProcessWrapper,
  VideoWrapper,
  PlayButton,
  Svg,
} from "./FlightProcessElements";

const FlightProcess = () => {
  return (
    <>
      <FlightProcessContainer>
        <FlightProcessWrapper>
          <VideoWrapper>
            <StaticImage src="Bischling.png" alt="A dinosaur" />
            <a
              href="https://www.youtube-nocookie.com/embed/aeQFGnDCHq8"
              target="_blank"
              rel="noreferrer"
            >
              <PlayButton>
                <Svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 311.69 311.69"
                >
                  <path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z" />
                  <polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84" />
                </Svg>
              </PlayButton>
            </a>
          </VideoWrapper>
        </FlightProcessWrapper>
      </FlightProcessContainer>
    </>
  );
};

export default FlightProcess;
