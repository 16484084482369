import React, { useState } from "react";
import { Button, ButtonScrollLink } from "../ButtonElement";
import { getImage } from "gatsby-plugin-image";
import { FaAngleRight } from "react-icons/fa";
import {
  Column2,
  Img,
  ImgWrap,
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrapMobileOnly,
  FlightModalMobileImageHeaderWrapper,
} from "./InfoElements";
import FlightDetailModal from "../Utils/FlightDetailModal";

import { RichText } from "@graphcms/rich-text-react-renderer";
import ImageSlider from "./ImageSlider";

const InfoSection = ({
  data: {
    fluggebietTopline,
    fluggebietHeadline,
    fluggebietBeschreibung,
    fluggebietImage,
    imageAltText,
    buttonPreis,
    buttonDetails,
  },
  design: {
    id,
    lightBg,
    imgStart,
    lightText,
    darkText,
    primary,
    dark,
    dark2,
    buttonDetailLinkTo,
  },
  detail,
}) => {
  const image = getImage(fluggebietImage?.localFile?.childImageSharp);

  const [showModel, setShowModel] = useState(false);
  const handleModalClose = () => {
    setShowModel(false);
  };
  const handleShowModal = () => setShowModel(true);

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{fluggebietTopline}</TopLine>
                <Heading lightText={lightText}>{fluggebietHeadline}</Heading>
                <ImgWrapMobileOnly>
                  <ImageSlider images={detail.detailImage} />
                </ImgWrapMobileOnly>
                <Subtitle darkText={darkText}>
                  {fluggebietBeschreibung}
                </Subtitle>

                <BtnWrap>
                  <ButtonScrollLink
                    data-id="booking"
                    to={`booking`}
                    duration={500}
                    exact="ture"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonPreis}
                  </ButtonScrollLink>

                  <Button
                    onClick={handleShowModal}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonDetails}
                  </Button>
                  <FlightDetailModal
                    show={showModel}
                    handleClose={handleModalClose}
                    title={detail.detailTopline}
                  >
                    <FlightModalMobileImageHeaderWrapper>
                      <ImageSlider images={detail.detailImage} />
                    </FlightModalMobileImageHeaderWrapper>
                    <InfoRow style={{ padding: "1rem" }}>
                      <Column1>
                        <RichText
                          content={detail.detailText.raw}
                          renderers={{
                            ul: ({ children }) => (
                              <ul
                                style={{
                                  listStyleType: "none",
                                }}
                              >
                                {children}
                              </ul>
                            ),
                            li: ({ children }) => (
                              <li>
                                <FaAngleRight style={{ marginLeft: "-1rem" }} />
                                {children}
                              </li>
                            ),
                          }}
                        />
                      </Column1>
                      <Column2>
                        <ImgWrap>
                          <ImageSlider images={detail.detailImage} />
                        </ImgWrap>
                      </Column2>
                    </InfoRow>
                  </FlightDetailModal>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img image={image} alt={imageAltText} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
