import { useState } from "react";
import axios from "axios";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";

  switch (lang) {
    case "de":
      return "de";
    default:
      return "en";
  }
};
const useContactForm = (validate) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    langu: getRedirectLanguage(),
  });
  const [errors, setErrors] = useState({});
  const [submissionState, setSubmissionState] = useState({
    submissionCompleted: false,
    statusCode: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({});
  };
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate(values);

    if (Object.keys(error).length === 0) {
      /*    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contactForm",
          ...values,
        }),
      }) */
      axios
        .post("/.netlify/functions/voucher-request-created", {
          formName: "contactForm",
          ...values,
        })
        .then((result) => {
          if (result.status === 200) {
            setValues({ ...values, name: "", email: "", message: "" });
            setSubmissionState({
              submissionCompleted: true,
              statusCode: result.status,
            });
          } else {
            setErrors({
              sentFailed:
                "Your request could not be sent. Please contact info@paragleitensalzburg.at directly per mail.",
            });
          }
        })
        .catch((error) => alert(error));
    } else {
      setErrors(error);
    }
  };

  return { handleChange, values, handleSubmit, errors, submissionState };
};
export default useContactForm;
