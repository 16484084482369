import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrapper,
  SocialMedia,
  SocialMediaWrapper,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <FooterContainer>
        {/*  <Map /> */}
        {/*         <div>
          <iframe
            width="100%"
            height="550"
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC6jQ4lXVd3JRWeROzzsNa9BS9LhgR6y7E
    &amp;q=Space+Needle,Seattle+WA"
          ></iframe>
        </div> */}
        <FooterWrapper>
          {/*      <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>About us</FooterLinkTitle>
                <FooterLink to="/signin">Link 1</FooterLink>
                <FooterLink to="/signin">Link 2</FooterLink>
                <FooterLink to="/signin">Link 3</FooterLink>
                <FooterLink to="/signin">Link 4</FooterLink>
                <FooterLink to="/signin">Link 5</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Videos</FooterLinkTitle>
                <FooterLink to="/signin">Video1</FooterLink>
                <FooterLink to="/signin">Video2</FooterLink>
                <FooterLink to="/signin">Video3</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
   
              <FooterLinkItems>
                <FooterLinkTitle>Social Media</FooterLinkTitle>
                <FooterLink to="/signin">Facebook</FooterLink>
                <FooterLink to="/signin">Instagram</FooterLink>
                <FooterLink to="/signin">Youtube</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer> */}

          <SocialMedia>
            <SocialMediaWrapper>
              <SocialLogo to="/" onClick={toggleHome}>
                Paragleiten Salzburg
              </SocialLogo>
              <WebsiteRights>
                &copy;{new Date().getFullYear()} paragleitensalzburg.at
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/paragleitensalzburg/"
                  target="_blank"
                  area-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/paragleitensalzburg/"
                  target="_blank"
                  area-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.youtube.com/@paragleitensalzburg5430/videos"
                  target="_blank"
                  area-label="Youtube"
                >
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" area-label="Twitter">
                  {/*  <FaTwitter /> */}
                </SocialIconLink>
                <SocialIconLink href="/" target="_blank" area-label="Linkedin">
                  {/*     <FaLinkedin /> */}
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrapper>
          </SocialMedia>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
