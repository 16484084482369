export default function validateInfo(values) {
  let errors = {};

  if (!values.namePassenger.trim()) {
    errors.namePassenger = "PASSENGER_NAME_REQUIRED";
  }

  if (!values.flightType.trim()) {
    errors.flightType = "FLIGHT_TYPE_REQUIRED";
  }

  if (!values.withSDCard.trim()) {
    errors.withSDCard = "SD_CARD_REQUIRED";
  }

  if (!values.name.trim()) {
    errors.name = "NAME_REQUIRED";
  }

  if (!values.email.trim()) {
    errors.email = "EMAIL_REQUIRED";
  }

  if (!values.street.trim()) {
    errors.street = "STREET_REQUIRED";
  }

  if (!values.ort.trim()) {
    errors.ort = "ORT_REQUIRED";
  }

  if (!values.delivery.trim()) {
    errors.delivery = "REQUIRED_DELIVERY_REQUIRED";
  }

  return errors;
}
