import React, { useState, useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavItem,
  NavLinks,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavBtn,
  MobileIcon,
} from "./NavbarElements";
import { animateScroll as scroll } from "react-scroll";

import Sidebar from "../Sidebar";

const Navbar = ({ page, bgStyle }) => {
  const intl = useIntl();
  const location = intl.locale;

  const [scrollNav, setScrollNav] = useState(false);
  const [selected, setSelected] = useState("");
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavItemClick = (e) => {
    setSelected(e.target.dataset.id);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Nav
        page={page}
        scrollNav={scrollNav}
        bgstyle={bgStyle === "solid" ? 1 : 0}
      >
        <NavbarContainer>
          <NavLogo to={``} onClick={toggleHome}>
            PARAGLEITEN SALZBURG
          </NavLogo>
          <MobileIcon
            onClick={toggle}
            scrollNav={scrollNav}
            bgstyle={bgStyle === "solid" ? 1 : 0}
          >
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                onClick={handleNavItemClick}
                data-id="flightAreas"
                to={`flightAreas`}
                scrollnav={scrollNav ? 1 : 0}
                bgstyle={bgStyle === "solid" ? 1 : 0}
                className={selected === "flightAreas" ? "active" : ""}
              >
                {intl.formatMessage({ id: "flightAreas" })}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                data-id="booking"
                onClick={handleNavItemClick}
                to={`booking`}
                scrollnav={scrollNav ? 1 : 0}
                bgstyle={bgStyle === "solid" ? 1 : 0}
                className={selected === "booking" ? "active" : ""}
              >
                {intl.formatMessage({ id: "booking" })}
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                data-id="flightProcess"
                onClick={handleNavItemClick}
                to="flightProcess"
                scrollnav={scrollNav ? 1 : 0}
                bgstyle={bgStyle === "solid" ? 1 : 0}
                className={selected === "flightProcess" ? "active" : ""}
              >
                {intl.formatMessage({ id: "process" })}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                data-id="voucher"
                to="voucher"
                onClick={handleNavItemClick}
                scrollnav={scrollNav ? 1 : 0}
                bgstyle={bgStyle === "solid" ? 1 : 0}
                className={selected === "voucher" ? "active" : ""}
              >
                {intl.formatMessage({ id: "voucher" })}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                data-id="contact"
                to="contact"
                onClick={handleNavItemClick}
                scrollnav={scrollNav ? 1 : 0}
                bgstyle={bgStyle === "solid" ? 1 : 0}
                className={selected === "contact" ? "active" : ""}
              >
                {intl.formatMessage({ id: "contact" })}
              </NavLinks>
            </NavItem>
          </NavMenu>

          <NavBtn></NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
