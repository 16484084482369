import React from "react";
import styled from "styled-components";
import RadioButton from "../elements/RadioButton";
/* import { ImgWrap } from "../InfoSection/InfoElements"; */
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
const VoucherForm = ({ handleChange, values, handleSubmit, errors }) => {
  const intl = useIntl();

  return (
    <VoucherWrapper>
      <form
        name="voucherForm"
        onSubmit={handleSubmit}
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="contact-email"
          value={values.contactEmail}
          autoComplete="off"
        />
        <VoucherRow>
          <VoucherColumn1>
            <FormWrapper>
              <FormInputs>
                <FormLabel htmlFor="namePassenger">
                  <FormattedMessage id="namePassenger" />
                </FormLabel>
                <FormInput
                  id="namePassenger"
                  type="text"
                  name="namePassenger"
                  value={values.namePassenger}
                  onChange={handleChange}
                ></FormInput>
                {errors.namePassenger && (
                  <ValidationError>
                    {intl.formatMessage({ id: errors.namePassenger })}
                  </ValidationError>
                )}
              </FormInputs>
              <FormInputs>
                <FormGroupWrapper>
                  <FormGroupText>
                    <FormattedMessage id="flightType" />
                  </FormGroupText>
                </FormGroupWrapper>
                <RadioButtonList>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "panorama" })}
                      name="flightType"
                      value="panorama"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "thermic" })}
                      name="flightType"
                      value="thermic"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "summit" })}
                      name="flightType"
                      value="summit"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "action" })}
                      name="flightType"
                      value="action"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                </RadioButtonList>
              </FormInputs>
              {errors.flightType && (
                <ValidationError>
                  {intl.formatMessage({ id: errors.flightType })}
                </ValidationError>
              )}
              <FormInputs>
                <FormGroupWrapper>
                  <FormGroupText>
                    <FormattedMessage id="withSDCard" />
                  </FormGroupText>
                </FormGroupWrapper>
                <RadioButtonList>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "yes" })}
                      name="withSDCard"
                      value="true"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                  <RadioButtonListItem>
                    <RadioButton
                      label={intl.formatMessage({ id: "no" })}
                      name="withSDCard"
                      value="false"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                </RadioButtonList>
              </FormInputs>
              {errors.withSDCard && (
                <ValidationError>
                  {intl.formatMessage({ id: errors.withSDCard })}
                </ValidationError>
              )}
            </FormWrapper>
          </VoucherColumn1>
          <VoucherColumn2>
            <FormWrapper>
              <FormInputs>
                <FormLabel htmlFor="name">
                  <FormattedMessage id="name" />
                </FormLabel>
                <FormInput
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                ></FormInput>
                {errors.name && (
                  <ValidationError>
                    {intl.formatMessage({ id: errors.name })}
                  </ValidationError>
                )}
              </FormInputs>
              <FormInputs>
                <FormLabel htmlFor="email">E-Mail</FormLabel>
                <FormInput
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                ></FormInput>
                {errors.email && (
                  <ValidationError>
                    {intl.formatMessage({ id: errors.email })}
                  </ValidationError>
                )}
              </FormInputs>
              <FormInputs>
                <FormLabel htmlFor="street">
                  {" "}
                  <FormattedMessage id="street" />
                </FormLabel>
                <FormInput
                  id="street"
                  type="text"
                  name="street"
                  value={values.street}
                  onChange={handleChange}
                ></FormInput>
                {errors.street && (
                  <ValidationError>
                    {intl.formatMessage({ id: errors.street })}
                  </ValidationError>
                )}
              </FormInputs>
              <FormInputs>
                <FormLabel htmlFor="ort">
                  <FormattedMessage id="ort" />
                </FormLabel>
                <FormInput
                  id="ort"
                  type="text"
                  name="ort"
                  value={values.ort}
                  onChange={handleChange}
                ></FormInput>
                {errors.ort && (
                  <ValidationError>
                    {intl.formatMessage({ id: errors.ort })}
                  </ValidationError>
                )}
              </FormInputs>
              <FormInputs>
                <FormGroupWrapper>
                  <FormGroupText>
                    <FormattedMessage id="delivery" />
                  </FormGroupText>
                </FormGroupWrapper>
                <RadioButtonList>
                  <RadioButtonListItem>
                    <RadioButton
                      label="EMail"
                      name="delivery"
                      value="mail"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                  <RadioButtonListItem>
                    <RadioButton
                      label="Post"
                      name="delivery"
                      value="post"
                      onChange={handleChange}
                    />
                  </RadioButtonListItem>
                </RadioButtonList>
              </FormInputs>
              {errors.delivery && (
                <ValidationError>
                  {intl.formatMessage({ id: errors.delivery })}
                </ValidationError>
              )}
              <FormInputs>
                <FormLabel htmlFor="info">
                  <FormattedMessage id="notes" />
                </FormLabel>
                <FormInput
                  id="message"
                  type="text"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                ></FormInput>
              </FormInputs>
            </FormWrapper>
          </VoucherColumn2>
          {errors.sentFailed && (
            <ValidationError>{errors.sentFailed}</ValidationError>
          )}
        </VoucherRow>
      </form>
    </VoucherWrapper>
    /*  </InfoContainer> */
  );
};

export default VoucherForm;

const VoucherColumn1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;
const VoucherColumn2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const FormInputs = styled.div`
  color: #212121;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const FormLabel = styled.label`
  color: #212121;
  padding: 12px 12px 12px 0;
  display: inline-block;
`;
const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0;
  }
`;
const FormWrapper = styled.div``;

const RadioButtonList = styled.ul`
  padding: 0.5rem 2rem;
`;
const RadioButtonListItem = styled.li`
  display: flex;
  align-items: center;
  height: 1.5rem;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: none;
`;
const FormGroupWrapper = styled.div`
  padding-top: 12px;
`;
const FormGroupText = styled.span`
  color: #212121;
`;

const VoucherWrapper = styled.div`
  display: grid;
  z-index: 1;
  // height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  /*  justify-content: center; */
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;
const VoucherRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  /*   align-items: center; */
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1 ' 'col2 col2'`};
  }
`;

const ValidationError = styled.p`
  color: red;
  font-size: 14px;
  padding: 0rem 2rem;
`;
