import React from "react";
import styled from "styled-components";
import {
  ContactContainer,
  ContactRow,
  ContactWrapper,
  ContactColumLeft,
  ContactColumRight,
} from "./ContactElements";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { useTeamQuery } from "../../hooks/useTeamQuery";
import ContactForm from "../ContactForm";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
const Contact = () => {
  const teamData = useTeamQuery();
  const intl = useIntl();

  return (
    <>
      <ContactContainer id="contact">
        <ContactWrapper>
          <ContactRow>
            <ContactColumLeft>
              <TeamCardWrapper>
                {teamData.allGraphCmsTeam.edges.map((edge) => {
                  const image = getImage(
                    edge.node.image.localFile.childImageSharp
                  );
                  return (
                    <TeamCard key={edge.node.id}>
                      <TeamCardItems>
                        <MemberImage>
                          <GatsbyImage
                            image={image}
                            alt={edge.node.name}
                            placeholder="blurred"
                            layout="fullWidth"
                            style={{ borderRadius: 100 }}
                          />
                        </MemberImage>
                        <MemberName>{edge.node.name}</MemberName>
                      </TeamCardItems>
                    </TeamCard>
                  );
                })}
              </TeamCardWrapper>
            </ContactColumLeft>
            <ContactColumRight>
              <ContactFormWrap>
                <ContactFormTitle>
                  {intl.formatMessage({ id: "contact" })}
                </ContactFormTitle>
                <ContactFormText>
                  <FormattedMessage id="contactMessage" />
                </ContactFormText>
                <ContactTel href="tel:+436644367274">
                  <FaPhoneAlt
                    style={{ color: "white", marginRight: "0.5rem" }}
                  />
                  +43 (0) 664 436 72 74
                </ContactTel>
                <ContactMail href="mailto:info@paragleitensalzburg.at">
                  <FaEnvelope
                    style={{ color: "white", marginRight: "0.5rem" }}
                  />
                  info@paragleitensalzburg.at
                </ContactMail>

                <ContactForm />
              </ContactFormWrap>
            </ContactColumRight>
          </ContactRow>
        </ContactWrapper>
      </ContactContainer>
    </>
  );
};

export default Contact;

const TeamCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const TeamCard = styled.div`
  margin: 0.5rem;
  /*   height: 350px; */
  width: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;
const MemberImage = styled.div``;
const MemberName = styled.h3`
  color: #212121;
  /*  background-color: #ccc; */
  text-align: center;
  padding-top: 0.5rem;
`;

const TeamCardItems = styled.div`
  /*  background-color: #ccc; */
  width: 100%;
  height: 100%;
`;

const ContactFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;
const ContactFormTitle = styled.h3`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;

  @media screen and(max-width: 480px) {
    font-size: 32px;
  }
`;
const ContactFormText = styled.p`
  color: #fff;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
`;

const ContactText = styled.p`
  color: #fff;
  max-width: 440px;
`;
const ContactMail = styled.a`
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
`;
const ContactTel = styled.a`
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
`;
