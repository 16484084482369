import React, { useState } from "react";

import { Carousel } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH2,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { ButtonScrollLink } from "../ButtonElement";
import { FormattedMessage } from "gatsby-plugin-intl";

const HeroSection = ({ heroImages }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <Carousel>
          {heroImages.map((img) => {
            const gatsbyImage = getImage(img.node.image.localFile);

            return (
              <Carousel.Item key={img.node.id}>
                <GatsbyImage
                  image={gatsbyImage}
                  alt={img.node.image.slug || "Image"}
                  style={{ width: "100%", height: "100vh" }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </HeroBg>
      {/* <Carousel> */}
      {/* {heroImages[0] && <Image image={getImage(heroImages[0].node.image)} />} */}
      {/*         <div>
          {heroImages &&
            heroImages.map((img) => {
              const gatsbyImage = getImage(img.node.image);
              <Image image={gatsbyImage} />;
            })}
        </div> */}
      {/* </Carousel> */}
      {/*   <HeroBg> */}

      {/* </HeroBg> */}
      <HeroContent>
        <HeroH2>
          <FormattedMessage id="hero" />
        </HeroH2>
        {/*  <HeroP>Hebe mit uns ab!</HeroP> */}
        <HeroBtnWrapper onMouseEnter={onHover} onMouseLeave={onHover}>
          <ButtonScrollLink
            data-id="flightAreas"
            to={`flightAreas`}
            primary="true"
            dark="true"
            /*     smooth={true} */
            duration={500}
            /*  spy={true} */
            exact="true"
            offset={-80}
          >
            <FormattedMessage id="takeOff" />
            {hover ? <ArrowForward /> : <ArrowRight />}
          </ButtonScrollLink>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
