import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../ButtonElement";
import { useIntl } from "gatsby-plugin-intl";
const FlightDetailModal = ({ show, handleClose, title, children }) => {
  const intl = useIntl();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0" }}>{children}</Modal.Body>
      <Modal.Footer>
        <Button primary={0} onClick={handleClose}>
          {intl.formatMessage({ id: "close" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlightDetailModal;
