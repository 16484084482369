import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "@reach/router";

export const ButtonGatsbyLink = styled(GatsbyLink)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#7dab2b" : "#fff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#292929")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#648822" : "#ccc")};
  }
`;

export const ButtonAnchorLink = styled(AnchorLink)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#7dab2b" : "#fff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#292929")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#648822" : "#ccc")};
    color: #ccc;
  }
`;

export const ButtonRouterLink = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#7dab2b" : "#fff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#292929")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#648822" : "#ccc")};
  }
`;

export const ButtonScrollLink = styled(ScrollLink)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#7dab2b" : "#fff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#292929")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#648822" : "#ccc")};
    color: #ccc;
  }
`;

export const Button = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#7dab2b" : "#fff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  margin-left: 1rem;
  color: ${({ dark }) => (dark ? "#fff" : "#292929")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: ${({ primary }) => (primary ? "none" : "1px solid #ccc")};
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#648822" : "#ccc")};
  }
`;

export const SubmitButton = styled.button`
  border-radius: 50px;
  background: #7dab2b;
  white-space: nowrap;
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  float: right;
  margin-top: 1rem;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #648822;
  }
`;
