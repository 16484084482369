import { graphql, navigate } from "gatsby";
import React, { useEffect } from "react";

import Navbar from "../components/Navbar";
import "../css/styles.css";
import "@fontsource/open-sans";
import "@fontsource/mogra";
import "@fontsource/roboto";
import "bootstrap/dist/css/bootstrap.min.css";

import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
} from "../components/InfoSection/Data";

import Footer from "../components/Footer";
import FlightProcess from "../components/FlightProcess";
import Contact from "../components/Contact";
import { useFluggebieteQuery } from "../hooks/useFluggebieteQuery";
import { useFluggebieteDetailQuery } from "../hooks/useFluggebieteDetailQuery";
import SimplybookWidget from "../components/Utils/SimplybookWidget";
import Voucher from "../components/Voucher";

import SuspenseHelper from "../components/Utils/SuspenseHelper";
import { useIntl } from "gatsby-plugin-intl";
import { useIntoQuery } from "../hooks/useIntoQuery";
import Into from "../components/Into/Into";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";

  switch (lang) {
    case "de":
      return "de";
    default:
      return "en";
  }
};

const IndexPage = ({ data }) => {
  const intl = useIntl();
  useEffect(() => {
    getRedirectLanguage() === "en" && navigate(`/en/`, { replace: true });
  }, []);

  const locale = intl.locale; //getRedirectLanguage().includes("de") ? "de" : "en";
  const intoData = useIntoQuery(locale);
  const fluggebieteData = useFluggebieteQuery(locale);
  const fluggebieteDetailData = useFluggebieteDetailQuery(locale);
  /*   console.log(intoData[0].node); */
  return (
    <>
      <SEO>
        <Navbar page="main" />
        <HeroSection heroImages={data.allGraphCmsHeroImage.edges} />
        <Into data={intoData[0].node} />
        <span id="flightAreas" />
        <InfoSection
          design={homeObjOne}
          data={fluggebieteData[0].node}
          detail={fluggebieteDetailData[0].node}
        />
        <InfoSection
          design={homeObjTwo}
          data={fluggebieteData[1].node}
          detail={fluggebieteDetailData[1].node}
        />
        <InfoSection
          design={homeObjThree}
          data={fluggebieteData[2].node}
          detail={fluggebieteDetailData[2].node}
        />
        <span id="booking" />
        <SuspenseHelper fallback={<div>Loading...</div>}>
          <SimplybookWidget />
        </SuspenseHelper>

        {/*    <SimplybookWidget /> */}

        <span id="flightProcess" />
        <FlightProcess id="flightProcess" />
        <span id="voucher" />
        <Voucher />
        <Contact />
        <Footer />
      </SEO>
    </>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query HeroImageQuery {
    allGraphCmsHeroImage {
      edges {
        node {
          id
          slug
          image {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

const SEO = ({ children }) => (
  <>
    <title>Paragleiten Salzburg - Tandem paragleiten</title>
    <link id="icon" rel="icon" href="../images/favicon.ico" />

    <meta name="icon" href="../images/favicon.ico" />
    {/* Standard metadata tags */}
    <meta
      name="description"
      content="Haben wir nicht alle schon mal vom Fliegen geträumt? Verwirklichen Sie Ihren Traum und steigen Sie mit uns in luftige Höhen!"
    />
    {/* Facebook tags */}
    <meta property="og:type" content="article" />
    <meta
      property="og:title"
      content="Paragleiten Salzburg - Tandem paragleiten"
    />
    <meta
      property="og:description"
      content="Haben wir nicht alle schon mal vom Fliegen geträumt?"
    />
    {children}
  </>
);
