import React from "react";
import {
  Column2,
  Img,
  ImgWrap,
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrapMobileOnly,
  FlightModalMobileImageHeaderWrapper,
} from "../InfoSection/InfoElements";
import ImageSlider from "../InfoSection/ImageSlider";
import { ButtonScrollLink } from "../ButtonElement";
import { useIntl } from "gatsby-plugin-intl";

const Into = ({ data }) => {
  const intl = useIntl();
  const image = data.images[0].localFile.childrenImageSharp[0];
  return (
    <>
      <InfoContainer lightBg={true} id="into">
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <TopLine>{data.title}</TopLine>
                <Heading lightText={false}>Tandem Paragleiten Salzburg</Heading>
                <ImgWrapMobileOnly>
                  <ImageSlider images={data.images} />
                </ImgWrapMobileOnly>
                <Subtitle darkText={true} style={{ whiteSpace: "pre-line" }}>
                  {data.text}
                </Subtitle>

                <BtnWrap>
                  <ButtonScrollLink
                    data-id="booking"
                    to={`booking`}
                    duration={500}
                    exact="ture"
                    offset={-80}
                    primary={1}
                    dark={1}
                    dark2={1}
                  >
                    {intl.formatMessage({ id: "booking" })}
                  </ButtonScrollLink>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <ImageSlider images={data.images} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Into;
