import { useStaticQuery, graphql } from "gatsby";

export const useTeamQuery = () => {
  const teamData = useStaticQuery(graphql`
    query TeamQuery {
      allGraphCmsTeam {
        edges {
          node {
            id
            name
            image {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  return teamData;
};
