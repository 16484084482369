import React, { useEffect, useState } from "react";

const SuspenseHelper = ({ fallback, children }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  }, [isMounted]);
  return <div fallback={fallback}>{!isMounted ? fallback : children}</div>;
};

export default SuspenseHelper;
