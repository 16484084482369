import { useStaticQuery, graphql } from "gatsby";

export const useVoucherReturnMessageQuery = (locale) => {
  const result = useStaticQuery(graphql`
    query VoucherReturnMessageQuery {
      allGraphCmsVoucherReturnMessage {
        edges {
          node {
            voucherMessage {
              html
              raw
            }
            locale
          }
        }
      }
    }
  `);

  return result.allGraphCmsVoucherReturnMessage.edges.filter((edge) => {
    return edge.node.locale === locale;
  });
};
