export const homeObjOne = {
  id: "bischling",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  imgStart: false,
  dark: true,
  primary: true,
  darkText: false,
  buttonDetailLinkTo: "/fluggebiete#Bischling",
};

export const homeObjTwo = {
  id: "salzburg",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  imgStart: false,
  dark: true,
  primary: true,
  darkText: true,
  buttonDetailLinkTo: "/fluggebiete#Salzburg",
};

export const homeObjThree = {
  id: "lungau",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  imgStart: false,
  dark: true,
  primary: true,
  darkText: false,
  buttonDetailLinkTo: "/fluggebiete#Lungau",
};
