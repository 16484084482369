import React from "react";
import useForm from "./hooks/useContactForm";
import validate from "./functions/validateContactForm";
import {
  FormInputs,
  FormLabel,
  FormInput,
  ContactFormWrapper,
  FormTextArea,
  SubmitButtonWrapper,
} from "./ContactFormElements";
import { SubmitButton } from "../ButtonElement";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";

const ContactForm = () => {
  const { handleChange, values, handleSubmit, errors, submissionState } =
    useForm(validate);
  return (
    <>
      <ContactFormWrapper>
        <form
          name="contactForm"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input
            type="hidden"
            name="contact-email"
            value={values.contactEmail}
            autoComplete="off"
          />
          <FormInputs>
            <FormLabel htmlFor="name">
              <FormattedMessage id="name" />
            </FormLabel>
            <FormInput
              id="name"
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
            ></FormInput>
            {errors.name && <ValidationError>{errors.name}</ValidationError>}
          </FormInputs>
          <FormInputs>
            <FormLabel htmlFor="email">E-Mail</FormLabel>
            <FormInput
              id="email"
              type="text"
              name="email"
              value={values.email}
              onChange={handleChange}
            ></FormInput>
            {errors.email && <ValidationError>{errors.email}</ValidationError>}
          </FormInputs>
          <FormInputs>
            <FormLabel htmlFor="message">
              <FormattedMessage id="message" />
            </FormLabel>
            <FormTextArea
              id="message"
              name="message"
              value={values.message}
              onChange={handleChange}
            ></FormTextArea>
            {errors.message && (
              <ValidationError>{errors.message}</ValidationError>
            )}
          </FormInputs>
          {errors.sentFailed && (
            <ValidationError>{errors.sentFailed}</ValidationError>
          )}
          {submissionState.submissionCompleted ? (
            <SuccessMessage>
              <FormattedMessage id="thankYouMessage" />
            </SuccessMessage>
          ) : (
            <SubmitButtonWrapper>
              <SubmitButton type="submit">
                <FormattedMessage id="send" />
              </SubmitButton>
            </SubmitButtonWrapper>
          )}
        </form>
      </ContactFormWrapper>
    </>
  );
};

const ValidationError = styled.p`
  color: red;
  font-size: 14px;
  padding: 0rem 2rem;
`;

const SuccessMessage = styled.div`
  padding: 20px;
  background-color: #04aa6d;
  color: white;
  margin-bottom: 15px;
  border-radius: 5px;
`;

export default ContactForm;
