import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../ButtonElement";
import { FormattedMessage } from "gatsby-plugin-intl";

const VoucherModal = ({
  show,
  handleClose,
  title,
  children,
  handleSubmit,
  view,
  data,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>{children}</Modal.Body>
      <Modal.Footer>
        <Button primary={0} onClick={handleClose}>
          <FormattedMessage id="close" />
        </Button>
        {view === "FORM" && (
          <Button primary={1} dark={true} onClick={handleSubmit}>
            <FormattedMessage id="order" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default VoucherModal;
