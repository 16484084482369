import { useStaticQuery, graphql } from "gatsby";

export const useFluggebieteDetailQuery = (locale) => {
  const fluggebieteDetailData = useStaticQuery(graphql`
    query FluggebieteDetailQuery {
      allGraphCmsFluggebietDetail {
        edges {
          node {
            id
            locale
            detailTopline
            detailHeading
            detailText {
              raw
            }
            detailImage {
              id
              localFile {
                childrenImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  return fluggebieteDetailData.allGraphCmsFluggebietDetail.edges.filter(
    (edge) => {
      return edge.node.locale === locale;
    }
  );
};
