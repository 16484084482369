import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SideBtnWrap,
  SidebarLink,
  SidebarWrapper,
} from "./SidebarElements";
import { useIntl } from "gatsby-plugin-intl";
const Sidebar = ({ isOpen, toggle }) => {
  const intl = useIntl();
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="flightAreas" onClick={toggle}>
            {intl.formatMessage({ id: "flightAreas" })}
          </SidebarLink>

          <SidebarLink to="booking" onClick={toggle}>
            {intl.formatMessage({ id: "booking" })}
          </SidebarLink>
          <SidebarLink to="flightProcess" onClick={toggle}>
            {intl.formatMessage({ id: "process" })}
          </SidebarLink>
          <SidebarLink to="voucher" onClick={toggle}>
            {intl.formatMessage({ id: "voucher" })}
          </SidebarLink>
          {/*  <SidebarLink to="team" onClick={toggle}>
            Team
          </SidebarLink> */}
          <SidebarLink to="contact" onClick={toggle}>
            {intl.formatMessage({ id: "contact" })}
          </SidebarLink>
        </SidebarMenu>

        <SideBtnWrap>
          {/*      <SidebarRoute to="/signin" onClick={toggle}>
            Sign In
          </SidebarRoute> */}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
