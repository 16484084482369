import { useStaticQuery, graphql } from "gatsby";

export const useVoucherInfoQuery = (locale) => {
  const voucherData = useStaticQuery(graphql`
    query VoucherQuery {
      allGraphCmsVoucher {
        edges {
          node {
            id
            locale
            voucherTopline
            voucherHeadline
            voucherText
            voucherButtonLabel
            voucherImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);

  return voucherData.allGraphCmsVoucher.edges.filter((edge) => {
    return edge.node.locale === locale;
  });
};
