import styled from "styled-components";

export const FlightProcessContainer = styled.div`
  height: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  background: #010606; */
  background: #fff;
`;
export const FlightProcessWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
export const FlightProcessRow = styled.div``;

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
`;
export const IFrameVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const PlayButton = styled.div`
  position: absolute;
  max-width: 700px;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  :hover svg {
    cursor: pointer;
    fill: #ccc;
  }
`;

export const Svg = styled.svg`
  transition: all 0.2s ease;
  fill: #fff;
`;

export const VideoOverlay = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 30px;

  :before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: 0.8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  :hover:before {
    color: #eee;
  }
`;
