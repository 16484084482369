import { useState } from "react";
import axios from "axios";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";
  switch (lang) {
    case "de":
      return "de";
    default:
      return "en";
  }
};
const useVoucherForm = (validate) => {
  const [values, setValues] = useState({
    namePassenger: "",
    name: "",
    flightType: "",
    withSDCard: "",
    email: "",
    street: "",
    ort: "",
    delivery: "",
    message: "",
    langu: getRedirectLanguage(),
  });
  const [errors, setErrors] = useState({});
  const [submissionState, setSubmissionState] = useState({
    submissionCompleted: false,
    showSuccessDialog: false,
    statusCode: 0,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const error = validate(values);
    /* const error = {}; */
    if (Object.keys(error).length === 0) {
      /*       fetch("/.netlify/functions/voucher-request-created", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "voucherForm",
          ...values,
        }),
      }) */
      axios
        .post("/.netlify/functions/voucher-request-created", {
          formName: "voucherForm",
          ...values,
        })
        .then((result) => {
          if (result.status === 200) {
            resetValues();
            setSubmissionState({
              submissionCompleted: true,
              showSuccessDialog: result.status === 200,
              statusCode: result.status,
            });
          } else {
            setErrors({
              sentFailed:
                "Your request could not be sent. Please contact info@paragleitensalzburg.at directly per mail.",
            });
          }
        })
        .catch((error) => alert(error));
    } else {
      setErrors(error);
    }
  };

  const resetSubmission = () => {
    setSubmissionState({
      submissionCompleted: false,
      showSuccessDialog: false,
      statusCode: 0,
    });
    resetValues();
    setErrors({});
  };

  const resetValues = () => {
    setValues({
      ...values,
      namePassenger: "",
      name: "",
      flightType: "",
      withSDCard: "",
      email: "",
      street: "",
      ort: "",
      delivery: "",
      message: "",
    });
  };

  return {
    handleChange,
    values,
    setValues,
    handleSubmit,
    errors,
    submissionState,
    resetSubmission,
  };
};
export default useVoucherForm;
