import React from "react";
import styled from "styled-components";
import { Script } from "gatsby";

const Simplybook = () => {
  return (
    <>
      <Widget>
        <div id="widget" />

        <Script id="sb-widget-id">
          {`var widget = new SimplybookWidgetRW(
  {element:"widget",
  "widget_type":"iframe",
  "url":"https://para.simplybook.it",
  "theme":"simple_beauty_theme",
  "theme_settings":{
    "timeline_modern_display":"as_table",
    "timeline_hide_unavailable":"0",
    "timeline_show_end_time":"0",
    "sb_base_color":"#a2ad02",
    "display_item_mode":"block",
    "booking_nav_bg_color":"#d1e9c6",
    "body_bg_color":"#fdfdfd",
    "dark_font_color":"#494949",
    "light_font_color":"#ffffff",
    "btn_color_1":"#5e7da7",
    "sb_company_label_color":"#ffffff",
    "sb_busy":"#dad2ce",
    "sb_available":"#d3e0f1",
   },
"timeline":"modern_week",
"datepicker":"top_calendar",
"is_rtl":false,
"app_config":{"predefined":{ "provider": "2" }}
}
);`}
        </Script>
      </Widget>
    </>
  );
};

export default Simplybook;

const Widget = styled.div`
  width: 100%;
  height: 1700px;

  @media screen and (max-width: 1050px) {
    height: 100%;
  }
`;
