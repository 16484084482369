import { useStaticQuery, graphql } from "gatsby";

export const useIntoQuery = (locale) => {
  const intoData = useStaticQuery(graphql`
    query IntoQuery {
      allGraphCmsInto {
        edges {
          node {
            id
            locale
            text
            title
            images {
              id
              localFile {
                childrenImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  return intoData.allGraphCmsInto.edges.filter((edge) => {
    return edge.node.locale === locale;
  });
};
